@use "../abstracts" as abs;

.profile_main {
  max-width: 520px;
  margin: auto;
  margin-top: 40px;

  @include abs.respond-to(mobile) {
    margin-top: 20px;
  }

  .profile_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;
    padding-bottom: 40px;
    background-color: #fff;
    border-radius: 12px;
    align-items: center;

    .back_btn {
      margin: 0;
      justify-self: start;
      width: 100%;
      gap: 0px;

      span {
        color: abs.$primary-color;
        font-size: 18px;
      }
    }

    .upperSec {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .edit {
        text-wrap: nowrap;
        text-decoration: underline;
        color: abs.$primary-color;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .profilePic {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      width: 100%;
      border-bottom: 3px solid abs.$border;
      padding-bottom: 20px;

      .pic {
        max-width: 150px;
        position: relative;

        .img {
          padding: 4px;
          border: 2px solid abs.$border;
          border-radius: 50%;
          width: 100%;
        }

        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
        }
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: start;
      max-width: 340px;

      .input {
        display: flex;
        flex-direction: column;

        input {
          padding: 8px 0;
          // border-radius: 8px;
          font-size: 18px;
          font-weight: 600;
          border: none;
          border-bottom: 2px solid abs.$border;


          &:focus {
            outline: none;
          }
        }
      }

      .select {
        border: none;
        border-bottom: 2px solid abs.$border;
        z-index: 1;

        .css-13cymwt-control {
          border: none;

          .css-1fdsijx-ValueContainer {
            font-weight: 600;
            padding-left: 0;

            .css-1dimb5e-singleValue {
              font-size: 18px;
              color: abs.$text-Primary;
            }
          }

          .css-1hb7zxy-IndicatorsContainer {
            display: none;
          }
        }
      }

      .PhoneInput {
        padding: 8px 0;
        // border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        border: none;
        border-bottom: 2px solid abs.$border;

        input {
          border: none;
        }

        .PhoneInput--focus {
          input {
            outline: none;
          }
        }
      }
    }

    .policy {
      display: flex;
      justify-content: center;

      p {
        font-size: 12px;
        color: abs.$text-gray;
        max-width: 85%;
        text-align: center;

        a {
          text-decoration: none;
          color: abs.$text-blue;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 340px;
      margin-top: 30px;
      z-index: 0;
    }
  }


}

.drag_main {
  .upload_img {
    border: 1px solid var(--primary-color);
    border-radius: 12px;
    margin: 30px 0;
    padding: 20px;
    text-align: center;

    img {
      width: 120px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 50px;
      display: flex;
    }

    p {
      margin: 0;
    }

    .profile_pic {
      width: 500px;
    }

    @include abs.respond-to(tab) {
      .profile_pic {
        max-width: 500px;
      }
    }

    .crop_button {

      button {
        margin: 10px 0;

        &.croping_img {
          max-width: 500px;
        }
      }
    }
  }

  .buttonsSec {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
  }
}