@use "../abstracts/" as abs;

.argomento_main {

  .subBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;




    &.sticky {
      position: sticky;
      top: 0;
      z-index: 100;

      &.scrolled {
        background-color: abs.$primary-color;
        padding: 10px 20px;
        border-radius: 12px;
        transition: padding 0.3s ease, border-radius 0.3s ease;

        .selectAll {
          button {
            background-color: #1e67b3;

            &:before {
              background-color: darken(#1e67b3, 15%);
            }
          }
        }

        .back_btn {
          margin: 10px 0;
          padding: 0;
        }
      }

    }

  }

  .agomento_container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @include abs.respond-to(mobile) {
      grid-template-columns: 1fr;
    }

    .agromento_item {
      display: grid;
      grid-template-columns: 90px 1fr 24px;
      // flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 12px;
      padding: 20px;
      gap: 10px;

      .img {
        width: 90px;
      }

      @include abs.respond-to(mobile) {
        display: grid;
        grid-template-columns: 90px 1fr 24px;
        gap: 10px;
        padding: 15px;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 16px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        width: 100%;

        .info_count {
          display: flex;
          gap: 8px;

          span {
            font-size: 18px;
            color: abs.$warning;
          }

          img {
            width: 20px;
          }
        }

        @include abs.respond-to(mobile) {
          gap: 5px;
          align-items: start;
          text-align: start;

          span {
            font-size: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

    }


    .Check_input {
      svg {
        width: 24px;
        height: 24px;
        fill: abs.$primary-color;

        &:checked {
          background-color: abs.$primary-color;
        }
      }
    }


    img {
      max-width: 100%;
    }

  }

  .sButton {
    position: sticky;
    bottom: 0px;
    margin-top: 30px;
    z-index: 999;

    button {
      border-radius: 12px;
      width: 100%;
    }
  }
}