@use "../abstracts" as abs;

.download_app {
  background: abs.$primary-color;
  display: flex;
  justify-content: center;
  gap: 20px;
  color: white;
  align-items: center;
  margin-top: 18px;
  border-radius: 12px;

  @include abs.respond-to(tab) {

    flex-direction: column;
    gap: 0;
    padding: 0 10px;

    a {
      margin-bottom: 14px;
    }


    @include abs.respond-to(mobile) {
      text-align: center;
      padding: 0 20px;
    }
  }

  span {
    padding: 16px 0;
    font-size: 12px;
  }

  a {
    display: flex;

    img {
      width: 100px;
      border-radius: 6px;
    }
  }

  .n_download {
    display: flex;
    gap: 10px;
  }
}

.home_container {
  .title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin: 24px 3px;

    @include abs.respond-to(mobile) {
      font-size: 28px;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;

    @include abs.respond-to(mobile) {
      justify-content: space-between;
      gap: 20px;
    }

    .service {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: #fff;
      border-radius: 12px;
      padding: 15px;
      align-items: center;
      width: 230px;
      max-width: 230px;
      cursor: pointer;
      text-align: center;

      img {
        max-width: 100px;
      }

      span {
        font-size: 20px;
        font-weight: 500;
      }

      @include abs.respond-to(smallDesktop) {
        width: calc(50% - 20px);
        max-width: 100%;

        &.last {
          width: 100%;
          max-width: 100%;
          flex-direction: row;
        }

        @include abs.respond-to(tab) {
          span {
            font-size: 18px;
          }

          img {
            max-width: 80px;
          }
        }
      }
    }
  }
}