@use "../abstracts/" as abs;

.contectUs-main {
    margin: 20px 0;
    background: white;
    border-radius: 12px;
    padding: 20px;

    .center_logo {
        text-align: center;
    }

    .contect_us {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 24px;
        justify-content: space-around;
        margin: 30px auto;
        
        @include abs.respond-to(smallDesktop) {
            grid-template-columns: 360px 1fr;

            @include abs.respond-to(tab) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        .contect_info {
            padding: 20px;
            border-radius: 12px;
            background-color: abs.$primary-color;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: inherit;
            position: relative;
            overflow: hidden;

            .left_contect p {
                margin: 0;
            }


            .f_sec_3 {
                ul {
                    padding: 0 10px;

                    &:first-child {
                        color: #fff;
                        font-size: 20px;
                        font-weight: 500;
                    }

                    li {
                        display: flex;
                        gap: 10px;
                        padding: 8px 0;
                        align-items: center;

                        a {
                            font-size: 16px !important;
                            text-decoration: none;
                            color: #242424 !important;
                            padding: 5px 0;
                            font-weight: 400 !important;
                            cursor: pointer;
                            display: block;
                        }
                    }
                }
            }

            .social_icons {
                ul {
                    display: flex;
                    gap: 20px;

                    li {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            width: 30px;
                            height: 30px;
                            background-color: var(--orange-primary);
                            border-radius: 100%;
                            transition: 0.3s;

                            &:hover {
                                background-color: var(--primary-white);
                                color: var(--primary-black);
                            }
                        }
                    }
                }
            }

            .curcles {

                .curcle {
                    position: absolute;
                    border-radius: 100%;
                    display: block;
                    width: 138px;
                    bottom: 80px;
                    right: 80px;
                    height: 138px;
                    background-color: #ffffff2e;
                }

                .curcle_big {
                    position: absolute;
                    border-radius: 100%;
                    display: block;
                    width: 250px;
                    bottom: -60px;
                    right: -60px;
                    height: 250px;
                    background-color: #ffffff2e;
                }

            }
        }

        .modal_form {
            margin-bottom: 10px;

            .main_div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                @include abs.respond-to(smallDesktop) {
                    grid-template-columns: 1fr;
                }

                .input_div {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    input {
                        padding: 10px 15px;
                        border-radius: 8px;
                        border: 2px solid abs.$border;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .text_area {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 20px;

                textarea {
                    padding: 10px 15px;
                    border-radius: 8px;
                    border: 2px solid abs.$border;

                    &:focus {
                        outline: none;
                    }
                }
            }



        }
    }
}