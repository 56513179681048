@use "../abstracts" as abs;

.login_main {
  max-width: 400px;
  margin: auto;
  margin-top: 40px;

  .login_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    align-items: center;

    .logo {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      max-width: 100px;

      img {
        width: 100%;
      }

      span {
        font-size: 20px;
        font-weight: 600;
        color: abs.$primary-color;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: start;

      .input {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .error {
          font-size: 12px;
          color: abs.$warning;
        }

        input {
          padding: 10px 15px;
          border-radius: 8px;
          border: 2px solid abs.$border;

          &:focus {
            outline: none;
          }
        }
      }

      .PhoneInput {
        border-radius: 8px;
        border: 2px solid abs.$border;
        padding-left: 15px;

        input {
          border: none;
        }

        .PhoneInput--focus {
          input {
            outline: none;
          }
        }
      }
    }

    .policy {
      display: flex;
      justify-content: center;

      .forget {
        text-decoration: none;
        color: abs.$text-blue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        font-size: 12px;
        color: abs.$text-gray;
        max-width: 85%;
        text-align: center;

        a {
          text-decoration: none;
          color: abs.$text-blue;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}