@use "../../../abstracts" as abs;

.btnr {
  padding: 16px 30px;
  font-family: abs.$font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 150%;
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  border: none;
  letter-spacing: 0.1em;
  text-decoration: none !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #fff;

  @include abs.respond-to(mobile) {
    padding: 10px 18px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(abs.$primary-color, 15%);
    transition: all 0.4s;
    border-radius: 10rem;
    z-index: -1;
  }

  &:hover {
    color: #fff !important;

    &:before {
      width: 100%;
    }
  }

  &.sm {
    padding: 10px 25px;
    border-radius: 8px;
    line-height: 100%;

    &:before {
      border-radius: 8px;
    }
  }

  &.primary {
    color: white;
    background-color: abs.$primary-color;
  }

  &.secoundary {
    background-color: abs.$secoundary-color;
    color: #242424;
  }

  &.warning {
    color: #fff !important;
    background-color: abs.$warning;
  }

  &.blue {
    background-color: abs.$sky-blue;
    color: #fff !important;
  }

  &.outline {
    background-color: transparent;
    color: abs.$primary-color;
    border: 2px solid abs.$primary-color;
  }
}

.back_btn {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  padding: 0px 0px;
  background-color: transparent;
  border-radius: 0px;
  transition: padding 0.3s ease, border-radius 0.3s ease 0.3s, background-color 0.3s ease; 
  
  &.sticky{
    position: sticky;
    top: 0;
    z-index: 100;

    &:hover {
      background-color: abs.$primary-color;
      padding: 10px 20px;
      border-radius: 12px;
      transition: padding 0.3s ease, border-radius 0.3s ease, background-color 0.3s ease;
      color: white; 
    }
  
    &.scrolled {
      background-color: abs.$primary-color;
      padding: 10px 20px;
      border-radius: 12px;
      transition: padding 0.3s ease, border-radius 0.3s ease; 
    }
  }



  img {
    cursor: pointer;
  }

  span {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
  }

  @include abs.respond-to(mobile) {
    margin: 15px 0;

    span {
      font-size: 20px;
    }

    img {
      width: 34px;
    }
  }
}