@use "../abstracts/" as abs;

.functtionality_main {
    padding-bottom: 1px;

    .functionality {
        h1 {
            font-size: 34px;
        }

        background-color: #fff;
        border-radius: 12px;
        padding: 25px;
        text-align: left;
        margin-top: 30px;
        font-family: abs.$font-family;
        margin-bottom: 30px;
    }
}