@use "../../abstracts/" as abs;

.main_footer {
  .footer {
    background-color: #ededed;
    border-radius: 24px;
    margin-bottom: 20px;

    .main_container {
      display: grid;
      grid-template-columns: 4fr 1fr;
      padding: 55px 0;

      @include abs.respond-to(smallDesktop) {
        grid-template-columns: 1fr 1fr;

        @include abs.respond-to(tab) {
          grid-template-columns: 1fr;

          @include abs.respond-to(mobile) {
            grid-template-columns: 1fr;
          }
        }
      }

      .f_sec {
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include abs.respond-to(tab) {
          grid-template-columns: repeat(2, 1fr);

          @include abs.respond-to(mobile) {
            grid-template-columns: 1fr;
          }
        }

        .f_sec_1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 130px;
          }

          ul {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 0;

            li {
              a {
                img {
                  width: 16px;
                }
              }
            }
          }
        }

        .f_sec_2 {
          ul {
            padding: 0 10px;
        
            li {
              a {
                font-size: 16px;
                text-decoration: none;
                color: abs.$text-Primary;
                padding: 5px 0;
                font-weight: 400;
                cursor: pointer;
                display: block;
              }
            }
        
            > *:first-child {
              color: abs.$primary-color;
              font-size: 20px;
              font-weight: 500;
              padding: 8px 0;
            }
          }
        }

        .f_sec_3 {
          @include abs.respond-to(mobile) {
            font-size: 14px;
          }

          ul {
            padding: 0 10px;

            li {
              display: flex;
              gap: 10px;
              padding: 8px 0;
              align-items: center;

              a {
                font-size: 16px !important;
                text-decoration: none;
                color: abs.$text-Primary !important;
                padding: 5px 0;
                font-weight: 400 !important;
                cursor: pointer;
                display: block;
              }
            }

            >*:first-child {
              color: abs.$primary-color;
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }

      .f_sec_4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        @include abs.respond-to(smallDesktop) {
          justify-content: center;
        }

        img {
          width: 145px;
        }

        span {
          font-weight: 500;
          margin: 15px 0;
        }

        >*:last-child {
          margin-top: 10px;
          border-radius: 6px;
        }
      }
    }
  }

  .Copyright {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    background-color: #fff;
    padding: 15px 0;
    color: abs.$lightGray;

    @include abs.respond-to(mobile) {
      padding: 15px 20px;
    }
  }
}