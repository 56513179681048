/* HTML: <div class="loader"></div> */
@use "../../abstracts/" as abs;

.loaderWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    background: #fff;
    //need to fix

    .loader {
        width: 65px;
        aspect-ratio: 1;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 50px;
            box-shadow: 0 0 0 3px inset abs.$primary-color;
            animation: l4 2.5s infinite;
        }

        &:after {
            animation-delay: -1.25s;
        }

        @keyframes l4 {
            0% {
                inset: 0 35px 35px 0;
            }

            12.5% {
                inset: 0 35px 0 0;
            }

            25% {
                inset: 35px 35px 0 0;
            }

            37.5% {
                inset: 35px 0 0 0;
            }

            50% {
                inset: 35px 0 0 35px;
            }

            62.5% {
                inset: 0 0 0 35px;
            }

            75% {
                inset: 0 0 35px 35px;
            }

            87.5% {
                inset: 0 0 35px 0;
            }

            100% {
                inset: 0 35px 35px 0;
            }
        }
    }

}

/* HTML: <div class="loade2r"></div> */
/* HTML: <div class="loader"></div> */

.spinnerWrapper {

    .spinner {
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #25b09b;
        --_m:
            conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;

        &.white{
            background: #fff;
            width: 30px;
        }
    }

    @keyframes l3 {
        to {
            transform: rotate(1turn)
        }
    }
}