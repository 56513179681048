@use "../../abstracts/" as abs;

.quiz_page_main {
  max-width: 1050px;
  margin: auto;

  .quiz_box {
    background-color: #b5d9bb;
    padding: 20px;
    border-radius: 12px;

    .qTopSec {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;

      @include abs.respond-to(tab) {
        @include abs.respond-to(mobile) {
          // grid-template-columns: repeat(3, 1fr);

          > *:nth-child(2) {
            display: none;
          }
        }
      }

      .exam_board {
        display: flex;
        align-items: flex-start;
        width: 270px;
        align-items: center;

        img {
          max-width: 100px;
          box-sizing: border-box;
        }

        @include abs.respond-to(tab) {
          img {
            max-width: 50px;
            box-sizing: border-box;
          }

          @include abs.respond-to(tab) {
            width: auto;
          }
        }

        .q_left_text {
          font-size: 12px;
          color: var(--dark-blue);
          display: inline-block;
          line-height: 13px;
          font-weight: 400;

          .mainText {
            font-size: 12px;
            font-weight: 500;
          }

          @include abs.respond-to(tab) {
            .mainText {
              font-weight: 400 !important;
              width: 100px;
              display: block;
            }

            .extra_text {
              display: none;
            }
          }
        }
      }

      .qT_img {
        display: flex;
        justify-content: center;
        align-items: baseline;

        img {
          max-width: 100px;
        }

        @include abs.respond-to(tab) {
          box-sizing: border-box;

          img {
            max-width: 50px;
            width: 100%;
          }
        }
      }
    }
  }

  .domande_box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;

    .dom_sec {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      color: abs.$text-gray;
      padding: 0 5px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;

      @include abs.respond-to(tab) {
        font-size: 14px;
      }

      &.dom_sec_active {
        color: abs.$warning;
      }

      .dom_sec_serial {
        text-align: center;
      }
    }
  }

  .page_serial {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
    margin: 5px 0;
    text-align: center;
    font-size: 25px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;

    @include abs.respond-to(tab) {
      gap: 5px;
    }

    span {
      width: 100%;
      background-color: abs.$lightBlue;
      border-radius: 6px;
      color: abs.$text-gray;
      padding: 0 5px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      color: abs.$text-Primary;

      &.right_answer {
        background-color: rgba(#00ff00, 0.5);
      }

      &.wrong_answer {
        background-color: rgba(abs.$warning, 0.5);
      }

      &.focused {
        color: abs.$warning;
      }

      &.answerd {
        background-color: rgba(abs.$lightGray, 0.4);
      }
    }
  }

  .quiz_serial {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          @include abs.respond-to(mobile) {
            margin-right: 5px !important;
          }

          span {
            width: 20px;
            aspect-ratio: 20/20;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: abs.$lightGray;
            margin-top: 10px;
            cursor: pointer;

            @include abs.respond-to(mobile) {
              margin-top: 0px;
            }

            &.right_answer {
              background-color: rgba(#00ff00, 0.5);
            }

            &.wrong_answer {
              background-color: rgba(abs.$warning, 0.5);
            }

            &.focused {
              color: rgba(abs.$warning, 0.5);
            }

            &.answerd {
              background-color: rgba(abs.$lightGray, 0.4);
            }
          }
        }
      }
    }

    // display: grid;
    // grid-template-columns: repeat(30, 1fr);
    // gap: 2px;

    // @include abs.respond-to(tab) {
    //   grid-template-columns: repeat(15, 1fr) !important;

    //   @include abs.respond-to(mobile) {
    //     grid-template-columns: repeat(10, 1fr) !important;
    //   }
    // }
  }

  .quiz_area {
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 10px;
    margin-top: 20px;

    @include abs.respond-to(tab) {
      grid-template-columns: 1fr;
    }

    .left_area {
      background-color: #fff;
      border-radius: 6px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        max-width: 200px;
        max-height: 200px;

        img {
          width: auto;
          max-width: 200px;
          max-height: 200px;
          box-sizing: border-box;
          margin: auto;
        }
      }

      @include abs.respond-to(mobile) {
        max-height: 120px;

        .img {
          height: 100%;
          padding: 10px;

          img {
            height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .right_area {
    display: grid;
    gap: 10px;

    .right_area_uper {
      background-color: #fff;
      padding: 10px;
      border-radius: 6px;

      .quiz_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .buttons {
          display: flex;
          gap: 15px;
          align-items: center;

          @include abs.respond-to(mobile) {
            gap: 10px;
          }

          .sm {
            padding: 10px 20px;
          }

          .img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
            cursor: pointer;

            img {
              width: 100%;
            }
          }
        }

        .infos {
          .serial_quiz {
            width: 30px;
            font-size: 20px;
            text-align: center;
            border-radius: 6px;
            padding: 6px;
            border: 2px solid var(--border);
          }
        }
      }

      .question_box {
        display: flex;
        align-items: center;
        height: 200px;
        overflow-x: auto;

        .question {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          padding: 12px;
          border-radius: 6px;

          span {
            position: relative;
            cursor: pointer;
            font-size: 17px;
            font-weight: 600;
          }

          p {
            margin: 0;
            padding: 10px;
          }

          .indicator {
            // border: 1px solid abs.$border2;

            .indicator_sm {
              display: block;
              position: absolute !important;
              top: -75px;
              left: 50%;
              transform: translateX(-50%);
              border: 2px solid abs.$border2;
              padding: 10px;
              border-radius: 10px;
              background-color: #fff;
              z-index: 999;
            }

            &.full {
              position: absolute !important;
              top: -45px;
              left: 50%;
              transform: translateX(-50%);
              border: 2px solid abs.$border2;
              padding: 10px;
              border-radius: 10px;
              background-color: #fff;
            }

            .text {
              display: block;
              position: absolute !important;
              top: -55px;
              left: 50%;
              transform: translateX(-50%);
              border: 1px solid abs.$border2;
              padding: 10px;
              border-radius: 10px;
              background-color: #fff;
              text-wrap: nowrap;
              z-index: 1;
            }
          }

          .modal-body {
            .modal_image {
              max-width: 300px;
              margin: auto;
            }
          }
        }
      }
    }

    .right_area_bottom {
      background-color: #fff;
      display: flex;
      gap: 25px;
      justify-content: center;
      border-radius: 8px;
      padding-bottom: 10px;

      button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "Poppins";
        background-color: #fff;
        border: none;
        text-align: center;

        img {
          width: 50px;
          cursor: pointer;
        }
      }
    }
  }

  .submit_area {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;

    .tempo_box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;

      .tempo_btn {
        justify-content: space-between;
        display: flex;
        align-items: center;
      }

      .tempo {
        border-radius: 6px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        width: auto;
        padding: 10px;

        > *:first-child {
          display: block;
          width: 145px;
          font-size: 12px;
          color: abs.$text-blue;
          font-weight: 600;
        }

        > *:last-child {
          font-size: 35px;
          text-align: center;
          font-weight: 600;
        }
      }
    }

    .uper_scheda {
      display: flex;
      margin-top: 20px;
      gap: 20px;

      @include abs.respond-to(tab) {
        flex-direction: column-reverse;
      }

      .Scheda_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        max-width: 300px;

        @include abs.respond-to(tab) {
          display: none;
        }

        div {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          gap: 6px;

          > *:first-child {
            font-size: 14px;
            color: abs.$primary-blue;
            font-weight: 500;
          }

          > *:last-child {
            font-size: 20px;
            color: abs.$primary-blue;
            font-weight: 500;
            display: block;
            text-align: end;
            border-radius: 6px;
            background-color: #fff;
            padding-right: 15px;
          }
        }
      }

      .click_box {
        font-size: 14px;
        display: flex !important;
        flex-direction: column;
        color: var(--dark-blue);
        font-weight: 500;
        width: 100%;
        justify-content: space-between;
        // gap: 15px;

        @include abs.respond-to(tab) {
          height: 100%;
        }

        .clk_box {
          display: flex;
          align-items: center;
          text-align: center;
          gap: 20px;
          justify-content: center;

          .sub_click_box {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            justify-content: space-between;
            gap: 5px;
            width: 100%;
            align-items: center;

            &.skeleton {
              @include abs.respond-to(tab) {
                display: flex;
                flex-wrap: wrap;
              }
            }

            div {
              .click_icons {
                color: #fff;
                font-size: 45px;
                border: 3px solid #fff;
                padding: 5px;
                border-radius: 100%;
                cursor: pointer;

                @include abs.respond-to(tab) {
                  font-size: 30px;
                }
              }
            }
          }
        }

        .finish_btn {
          display: flex;
          align-items: center;
          gap: 10px;

          .chiudi_btn {
            background-color: #fff;
            border: none;
            padding: 5px;
            border-radius: 6px;
            width: 100%;
            font-weight: 500;
            font-family: "Poppins";
            cursor: pointer;
          }

          .repeat_icon {
            font-size: 26px;
            background: white;
            padding: 0 3px;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .answer_area {
    margin-top: 20px;
    background: #fff;
    padding: 10px;

    .ques {
      display: grid;
      grid-template-columns: 2fr 8fr 1fr 1fr;

      .ques_item {
        border: 1px solid abs.$border2; //need to fix

        .the_ques {
          padding: 10px;
          display: block;
        }

        .ques_title {
          background-color: #cbd9d2;
          width: 100%;
          display: block;
          border-bottom: 1px solid abs.$border2;
          font-weight: 600;
        }
      }

      .ques_result {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          display: block;
          border-bottom: 1px solid abs.$border2;
          font-weight: 700;
        }

        .ans {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          svg {
            width: 25px;
            height: 25px;
          }

          &.red {
            svg {
              fill: abs.$red;
            }
          }

          &.green {
            svg {
              fill: abs.$solidGreen;
            }
          }

          &.blue {
            svg {
              fill: abs.$primary-blue;
            }
          }
        }
      }

      .ques_index {
        background-color: #cbd9d2;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;

        span {
          font-size: 20px;
          font-weight: 500;
          padding-left: 10px;
        }

        img {
          max-width: 40px;
          box-sizing: border-box;
        }
      }
    }
  }

  .sb_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 300px;
    display: none;

    @include abs.respond-to(tab) {
      display: block;
      margin-top: 20px !important;
      max-width: 100% !important;
    }

    .divBox {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      > *:first-child {
        font-size: 14px;
        color: abs.$text-blue;
        font-weight: 500;
      }

      > *:last-child {
        font-size: 18px;
        color: abs.$text-blue;
        font-weight: 500;
        display: block;
        text-align: end;
        border-radius: 6px;
        background-color: #fff;
        padding-right: 15px;
      }
    }
  }

  &.skeleton {
    .quiz_box {
      .qTopSec {
        .qT_img {
          span {
            width: 90%;

            @include abs.respond-to(tab) {
              max-width: 100%;
            }
          }
        }
      }

      .domande_box {
      }
    }

    .quiz_serial {
      .swiper {
        .swiper-wrapper {
          .swiper-slide {
            span {
              width: 30px;
              aspect-ratio: auto !important;
              text-align: center;
              background-color: #fff;
              border-radius: 6px;
              box-sizing: border-box;
              display: inline-block;
              padding: 0;
              align-items: center;
              font-size: 14px;
              color: #62646a;
              margin-top: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .quiz_area {
    }
  }
}

.exam_modal h2 {
  color: var(--red);
  font-size: 30px;
  margin: 0;
}

.exam_modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px;

  .the_icon {
    font-size: 80px;
    text-align: center;
    width: 100%;
    margin: 20px 0;

    &.green_ans {
      color: abs.$primary-color;
    }

    &.red_ans {
      color: abs.$red;
    }
  }

  .purple {
    background-color: #8050ff !important;
  }

  .btnr:last-child {
    background-color: abs.$orange !important;
  }

  span {
    margin: 0;
    font-size: 18px;
    color: abs.$red;
    font-weight: 500;
  }

  button {
    margin-top: 20px;
  }
}

.tempoModal-main {
  .modal-dialog {
    max-width: 430px;

    @include abs.respond-to(mobile) {
      margin: 20px;
    }
  }

  .tempoModal {
    padding: 15px;
  }
}
