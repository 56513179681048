@use "../abstracts/" as abs;

.statistiche_main {
  max-width: 1000px;
  margin: auto;

  .titleSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .statistiche {
    grid-row-gap: 20px;

    .item_col {
      .statistiche_item {
        display: flex;
        gap: 10px;
        align-items: start;
        background-color: #fff;
        border-radius: 12px;
        padding: 25px;
        cursor: pointer;
        text-decoration: none;
        color: abs.$text-Primary;
        flex-direction: column;
        position: relative;

        .reset {
          position: absolute;
          right: 0;
          top: 0;
          padding: 5px 15px;
          background-color: abs.$warning;
          color: #fff;
          border-radius: 0px 12px 0px 12px;
        }

        .statistiche_upper {
          display: flex;
          width: 100%;
          gap: 10px;

          .img {
            align-items: center;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            overflow: hidden;
            width: 75px;
            height: 75px;
            // aspect-ratio: 90/45;

            img {
              height: auto;
              width: 100%;
            }

            @include abs.respond-to(mobile) {
              .img {
                width: 100%;
                margin: auto;
              }
            }
          }

          .info {
            display: flex;
            gap: 5px;
            flex-direction: column;
            justify-content: center;

            @include abs.respond-to(mobile) {
              width: 100%;
              text-align: start;
            }

            .image {
              display: flex;
              gap: 10px;
              align-items: center;
              color: abs.$warning;

              span {
                font-weight: 500;
                font-size: 15px;
              }
            }

            .item_name {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        .statistiche_bottom {
          .schoreBox {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: start;

            .schore {
              display: flex;
              gap: 10px;

              .sItem {
                display: flex;
                flex-direction: column;
                gap: 2px;
                align-items: center;
                font-size: 18px;
                font-weight: 600;
                color: abs.$primary-color;
                padding: 10px;
                border-radius: 12px;

                @include abs.respond-to(mobile) {
                  text-align: center;
                }

                .numb {
                  font-size: 20px;
                  font-weight: 700;
                }

                .text {
                  color: abs.$primary-color;
                  font-size: 14px;
                  font-weight: 500;
                  text-align: center;
                  line-height: 18px;
                }

                &.red {
                  color: abs.$red;
                  background-color: rgb(abs.$red, 10%);

                  .text {
                    color: abs.$red;
                    text-align: center;
                  }
                }

                &.black {
                  color: #000000;
                  background-color: rgb(#000000, 10%);

                  .text {
                    color: #000000;
                    text-align: center;
                  }
                }

                &.orange {
                  color: abs.$orange;
                  background-color: rgb(abs.$orange, 10%);

                  .text {
                    color: abs.$orange;
                    text-align: center;
                  }
                }

                &.green {
                  color: abs.$text-green;
                  background-color: rgb(abs.$text-green, 10%);

                  .text {
                    color: abs.$text-green;
                    text-align: center;
                  }
                }
              }

              @include abs.respond-to(mobile) {
                width: 100%;

                .sItem {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}