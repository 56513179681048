@use "../abstracts/" as abs;

.trafficoMain {
  .trafficoItem {
    max-width: 925px;
    margin: auto;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 12px;
    padding: 25px;

    .header {
      display: flex;
      justify-content: start;
      gap: 10px;
      align-items: start;
      margin-bottom: 10px;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-weight: 500;
        color: abs.$text-green;

        @include abs.respond-to(mobile) {
          font-size: 20px;
        }
      }

      .subSection {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 20px;

        .mainImage {
          max-width: 100px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;
      }
    }

    .info {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: start;

      @include abs.respond-to(mobile) {
        font-size: 16px;
        font-weight: 400;
      }

      .question {
        position: relative;
        flex-wrap: wrap;
        border-radius: 6px;

        span {
          cursor: pointer;
        }

        .indicator {

          .indicator_sm {
            display: block;
            position: absolute !important;
            top: -75px;
            left: 50%;
            transform: translateX(-50%);
            border: 2px solid abs.$border2;
            padding: 10px;
            border-radius: 10px;
            background-color: #fff;
            z-index: 1;
          }

          &.full {
            position: absolute !important;
            top: -45px;
            left: 50%;
            transform: translateX(-50%);
            border: 2px solid abs.$border2;
            padding: 10px;
            border-radius: 10px;
            background-color: #fff;
          }

          .text {
            display: block;
            position: absolute !important;
            top: -55px;
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid abs.$border2;
            padding: 10px;
            border-radius: 10px;
            background-color: #fff;
            text-wrap: nowrap;
            z-index: 1;
          }
        }
      }


    }

    .groupBtn {
      display: flex;
      gap: 15px;

      button {
        background-color: abs.$orange;
        padding: 15px 25px;
      }
    }

    .imageWrapper {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      margin-top: 15px;

      @include abs.respond-to(mobile) {
        grid-template-columns: repeat(6, 1fr);
      }

      .img {
        position: relative;
        width: 100%;
        cursor: pointer;

        img {
          width: 100%;
          display: block;
        }

        &:hover {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 1;
          }
        }

        .expand-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #000;
          font-size: 24px;
          opacity: 0;
          z-index: 2;
          transition: opacity 0.3s ease;
        }

        &:hover .expand-icon {
          opacity: 1;
        }
      }
    }


    &.skeleton {
      .subSection {
        flex-direction: column;
        align-items: start;

        .mainImage {
          display: flex;
          gap: 10px;
        }
      }
    }
  }

  .navButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    .btnr {
      background-color: black;
      color: #fff;
      gap: 10px;
    }
  }
}