#root {
  --dynamic-primary-color: #1eb365;
  --dynamic-secondary-color: #f5f5f5;
}

$primary-color: #1eb365;
$secoundary-color: #f5f5f5;
$primary-blue: #263077;

$sky-blue: #5db2ed;
$lightBlue: #F4F8FF;
$warning: #ff8a8a;
$orange: #ffb445;
$yellow: #fff534;
$red: #e22f38;

$text-Primary: #000000;
$text-green: #7cb940;
$text-gray: #9ca3af;
$text-white: #fff;
$text-blue: #263077;
$lightGray: #62646A;
$solidGreen: #22B34B;

$border: #d2d5da;
$border2: #B7A9AA

