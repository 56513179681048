@use "../abstracts/" as abs;

.ManualConQuiz_main {
  position: relative;
  z-index: 1;

  .ManualConQuiz {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include abs.respond-to(mobile) {
      grid-template-columns: 1fr;
      gap: 10px;
    }



    .item_col {
      .ManualConQuiz_item {
        display: flex;
        gap: 10px;
        background-color: #fff;
        border-radius: 12px;
        padding: 10px;
        cursor: pointer;
        text-decoration: none;
        color: abs.$text-Primary;
        height: 100%;
        align-items: center;

        .img {
          max-width: 83px;
          width: 83px;
        }

        .info {
          display: flex;
          gap: 5px;
          flex-direction: column;
          justify-content: center;

          .image {
            display: flex;
            gap: 10px;
            align-items: center;

            span {
              color: abs.$sky-blue;
              font-weight: 500;
              font-size: 15px;
            }
          }

          .item_name {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
          }
        }
      }
    }
  }
}