@use "../abstracts" as abs;

.importentNotesMain {
  // background-color: #fff;
  border-radius: 12px;
  margin: 20px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include abs.respond-to(tab) {
    grid-template-columns: 1fr;
  }

  .notesItem {
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .notesImg {
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 10px;
      align-items: center;

      p {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }

      img {
        width: 100%;
        // border-radius: 50%;
      }
    }
  }
}
