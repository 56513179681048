@use "./abstracts/" as abs;
@use "./components";
@use "./pages";

body {
  font-family: abs.$font-family;

  ul {
    list-style: none;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  .swal-text {
    text-align: center !important;
  }

  section {
    padding-bottom: 20px;
  }
}

.main_body {
  position: relative;
  z-index: 1;
}

.bg {
  min-height: 100vh;
  height: 100%;
  background-image: url(../assets/Background/bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.bg_green {
    min-height: 100vh;
    height: 100%;
    background-image: url(../assets/Background/bg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.App {
  .Container {
    margin: auto;
    padding: 0 15px;

    @include abs.respond-to(mobile) {
      max-width: 540px;
    }

    @include abs.respond-to(smallDesktop) {
      max-width: 960px;
    }

    @include abs.respond-to(tab) {
      max-width: 720px;
    }

    @include abs.respond-to(largeDesktop) {
      max-width: 1200px;
    }

    @include abs.respond-to(extraLargeDesktop) {
      max-width: 1440px;
    }
  }
}

section {
  margin: 20px 0px;
  overflow: hidden !important;

  @include abs.respond-to(mobile) {
    margin: 10px 0px;
  }

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    //margin-bottom: 0px;

    @include abs.respond-to(mobile) {
      margin-bottom: 20px;
    }
  }
}

.translationModal {
  .modal-content {
    .modal-body {
      .modal_image {
        max-width: 350px;
        margin: auto;

        img {
          width: 100%;
        }
      }
    }
  }
}

.zoomModal {
  .modal-content {
    max-width: 450px;
    margin: auto;

    @include abs.respond-to(mobile) {
      max-width: calc(300px - 30px);
    }
  }

  .modal-body {
    overflow: hidden;

    .zoomContainer {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;

      img {
        width: 100%;
        transition: transform 0.3s ease-in-out, transform-origin 0.1s ease;
      }
    }
  }
}

.video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
  }
}