@use "../../abstracts" as abs;

.main_header {
  padding-top: 30px !important;

  @include abs.respond-to(mobile) {
    padding-top: 15px !important;
  }

  .header_container {
    padding: 12px 25px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include abs.respond-to(mobile) {
      padding: 10px 15px;
    }

    .logo {
      display: flex;
      gap: 10px;
      align-items: center;

      .bar {
        display: none;
        font-size: 24px;
        cursor: pointer;

        @include abs.respond-to(mobile) {
          display: flex;
        }
      }

      img {
        width: auto;
        height: 50px;
        cursor: pointer;

        @include abs.respond-to(mobile) {
          height: 42px;
        }
      }
    }

    &.user_nav {
      .user_on_nav {
        display: flex;
        gap: 10px;
        align-items: center;

        .hover_drop {
          position: relative;

          .auth_user {
            margin: 0;
            gap: 10px;
            cursor: pointer;
            list-style: none;
            padding: 0;
            display: flex;
            gap: 5px;

            li {
              display: flex;
              align-items: center;

              .user_img {
                width: 37px;
                border-radius: 100%;
              }
            }

            .user_name {
              font-size: 14px;
              font-weight: 600;

              span {
                text-align: left;
              }
            }

            .down_icon {
              width: 24px;
            }
          }

          .drom_down {
            position: absolute;
            z-index: 1;
            width: 155px;
            border-radius: 10px;
            background-color: #f0f0f0;
            box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);
            transform: scaleY(0);
            transform-origin: top;
            transition-duration: 0.3s;
            right: 0;
            margin-top: 10px;

            .drop_box {
              display: block;
              padding: 10px;
              width: 155px;
              box-sizing: border-box;
              list-style: none;
              text-align: left;
              margin: 0;

              li {
                padding: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #333;
                gap: 8px;
                display: flex;
                align-items: center;
                transition: all 0.3s ease-in-out;

                a {
                  color: #000;
                  text-decoration: none;
                  padding: 0;
                }

                .sign_out {
                  cursor: pointer;
                }

                .d_icons {
                  width: 24px;
                  border-radius: 50%;
                  overflow: hidden;

                  img {
                    width: 100%;
                  }
                }

                &:hover {
                  background-color: #fff;
                  border-radius: 8px;
                }
              }
            }
          }

          &:hover .drom_down {
            transform: scaleY(1);
          }
        }
      }
    }
  }
}

.nav_items {
  nav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 20px;

    a {
      text-decoration: none;
      color: abs.$text-Primary;
      line-height: 150%;
      font-size: 18px;
      transition: all 0.2s ease-in-out;

      &:hover,
      &.active {
        color: abs.$primary-color;
      }
    }
  }
}

.membership {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffedd5;
  padding: 0px 15px;
  border-radius: 8px;

  .text {
    font-size: 14px;
  }

  .date {
    font-weight: 600;
    color: abs.$primary-color;
  }
}

.custom_offcanvas {
  max-width: 85%;
  border-radius: 0px 16px 16px 0px;

  .offcanvas-header {
    max-width: 100%;
    align-items: start;
    border-bottom: 2px solid abs.$border;

    .c_logo {
      max-width: 100px;

      img {
        width: 100%;
      }
    }
  }

  .offcanvas-body {
    padding: 0;

    nav {
      gap: 0px;

      @include abs.respond-to(mobile) {
        flex-direction: column;
      }

      a {
        padding: 10px 12px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover,
        &.active {
          background-color: rgb(abs.$primary-color, 20%);

          a {
            color: abs.$primary-color;
          }
        }
      }
    }
  }
}
