@use "../../abstracts/" as abs;

.stradaDetailsMain {
  position: relative;

  .titleAreaStrada {
  }

  .imageA {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      max-width: 100px;
      max-height: fit-content;
      border-radius: 8px;
    }

    span {
      font-weight: 600;
      color: #fff;
    }
  }

  .stadaContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include abs.respond-to(mobile) {
      grid-template-columns: 1fr;
    }

    .stradaItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #fff;
      border-radius: 12px;
      padding: 15px;
      gap: 10px;

      .upperSec {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .actions {
          display: flex;
          gap: 15px;

          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .buttons {
            display: flex;
            gap: 5px;
          }
        }

        .count {
          min-height: 30px;
          min-width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #000000;
          color: #fff;
          border-radius: 8px;
          padding: 0 5px;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: start;

        .topInfo {
          .question {
            position: relative;

            .indicator {
              .indicator_sm {
                background-color: white;
                padding: 10px;
                border: 1px solid gray;
                border-radius: 8px;
                top: -35px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;

                .spinner {
                  width: 28px;
                }
              }

              .text {
                position: absolute;
                top: -25px;
                background: white;
                padding: 6px;
                border: 1px solid gray;
                border-radius: 8px;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                font-size: 22px;
                text-wrap: nowrap;
              }
            }
          }
        }

        .answer {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: abs.$solidGreen;
          color: #fff;
          border-radius: 8px;
          font-weight: 600;
          min-width: 30px;
        }

        .falseColor {
          background-color: abs.$red;
        }
      }
    }

    &.skeleton {
      .stradaItem {
        .info {
          span {
            width: 100%;
          }
        }
      }
    }
  }

  .sButton {
    position: sticky;
    bottom: 0px;
    margin-top: 30px;
    z-index: 999;

    button {
      border-radius: 12px;
      width: 100%;

      &::before {
        border-radius: 12px;
      }
    }
  }
}
